<template>
  <div class="bill-detail-container">
    <my-nav-bar
      title="账单明细"
      left-text="返回"
      left-arrow
      right-text="修改账单"
      @click-right="toOrderView"
    />
    <div class="app-main-container">
      <div v-if="bill" class="bill-detail-content" :class="{'show-footer-btn': bill.status === 0}">

        <van-cell-group title="账单明细">
          <van-cell title="订单金额" :value="'￥' + bill.amount" />
          <van-cell title="已收定金" :value="'￥' + depositAmount" />
          <van-cell title="红包抵扣" :value="'￥' + bill.red_packet_amount" />
          <van-cell title="退货金额" :value="'￥' + bill.return_amount" />
          <van-cell title="签单金额" :value="'￥' + bill.unpaid_amount" />
          <van-cell title="尾数" :value="'￥' + bill.decimal_amount" />
          <van-cell title="报销" :value="'￥' + bill.expense_amount" />
        </van-cell-group>

        <van-cell-group title="支付明细">
          <van-cell title="线上支付" :value="'￥' + bill.wxpay_amount" />
          <van-cell title="支付宝" :value="'￥' + bill.alipay_amount" />
          <van-cell title="现金" :value="'￥' + bill.cash_amount" />
        </van-cell-group>

        <!-- <van-cell-group title="签单收款">
          <van-cell title="微信" :value="'￥' + bill.iou_wxpay_amount" />
          <van-cell title="支付宝" :value="'￥' + bill.iou_alipay_amount" />
          <van-cell title="现金" :value="'￥' + bill.iou_cash_amount" />
        </van-cell-group> -->

        <van-cell-group style="margin-top: 10px">
          <van-cell title="需交金额" :value="'￥' + bill.real_amount" />
        </van-cell-group>
        <div v-if="bill.status === 0" class="fixed-bottom-btn">
          <van-button square block text="确认结算" @click="finish" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import billApi from '@/api/bill'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'BillDetail',
  components: { myNavBar },
  data() {
    return {
      bill: null
    }
  },
  computed: {
    depositAmount() {
      return Number(this.bill.deposit_wxpay_amount) + Number(this.bill.deposit_alipay_amount) + Number(this.bill.deposit_cash_amount)
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const params = {
        bill_id: this.$route.query.bill_id
      }
      billApi.detail(params).then(res => {
        this.bill = res.data
      })
    },
    toOrderView() {

    }
  }
}
</script>

<style lang="scss" scoped>
  .bill-detail-content {
    padding: 0 15px 15px 15px;
    .van-cell {
      &__value {
        color: #ee0a24;
      }
    }
  }
  .show-footer-btn {
    padding-bottom: 65px;
  }
</style>
