import request from '@/utils/request'

export default {

  list(params) {
    return request({
      method: 'get',
      url: 'bill',
      params
    })
  },
  detail(params) {
    return request({
      method: 'get',
      url: 'bill/detail',
      params
    })
  },
  settlement(params) {
    return request({
      method: 'get',
      url: 'bill/settlement',
      params
    })
  },
  finish(data) {
    return request({
      method: 'post',
      url: 'bill/finish',
      data
    })
  },
  paymentDetail(params) {
    return request({
      method: 'get',
      url: 'bill/payment-detail',
      params
    })
  },
  orderPayment(params) {
    return request({
      method: 'get',
      url: 'bill/order-payment',
      params
    })
  }
}

export function getPaymentOnlines(params) {
  return request({
    method: 'get',
    url: 'bill/payment-online',
    params
  })
}

