var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bill-detail-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "账单明细",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "修改账单"
    },
    on: {
      "click-right": _vm.toOrderView
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.bill ? _c('div', {
    staticClass: "bill-detail-content",
    class: {
      'show-footer-btn': _vm.bill.status === 0
    }
  }, [_c('van-cell-group', {
    attrs: {
      "title": "账单明细"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "订单金额",
      "value": '￥' + _vm.bill.amount
    }
  }), _c('van-cell', {
    attrs: {
      "title": "已收定金",
      "value": '￥' + _vm.depositAmount
    }
  }), _c('van-cell', {
    attrs: {
      "title": "红包抵扣",
      "value": '￥' + _vm.bill.red_packet_amount
    }
  }), _c('van-cell', {
    attrs: {
      "title": "退货金额",
      "value": '￥' + _vm.bill.return_amount
    }
  }), _c('van-cell', {
    attrs: {
      "title": "签单金额",
      "value": '￥' + _vm.bill.unpaid_amount
    }
  }), _c('van-cell', {
    attrs: {
      "title": "尾数",
      "value": '￥' + _vm.bill.decimal_amount
    }
  }), _c('van-cell', {
    attrs: {
      "title": "报销",
      "value": '￥' + _vm.bill.expense_amount
    }
  })], 1), _c('van-cell-group', {
    attrs: {
      "title": "支付明细"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "线上支付",
      "value": '￥' + _vm.bill.wxpay_amount
    }
  }), _c('van-cell', {
    attrs: {
      "title": "支付宝",
      "value": '￥' + _vm.bill.alipay_amount
    }
  }), _c('van-cell', {
    attrs: {
      "title": "现金",
      "value": '￥' + _vm.bill.cash_amount
    }
  })], 1), _c('van-cell-group', {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "需交金额",
      "value": '￥' + _vm.bill.real_amount
    }
  })], 1), _vm.bill.status === 0 ? _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "square": "",
      "block": "",
      "text": "确认结算"
    },
    on: {
      "click": _vm.finish
    }
  })], 1) : _vm._e()], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }